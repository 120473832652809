import { preloadImages, preloadFonts, g } from './js/utils'
import Swiper, { Navigation, EffectCoverflow, HashNavigation } from 'swiper';
import { gsap, ScrollTrigger } from 'gsap/all';
import Album from './js/script';
import albums from './js/albums'
import Plyr from 'plyr';
// import "./audio/**/*.*";
// import "./entardecer.html"
import LocomotiveScroll from 'locomotive-scroll'
let album = new Album(albums.albums[0], '#musicList')

gsap.registerPlugin(ScrollTrigger);
const tab = document.querySelectorAll("[data-tab]")
const linkOpenFullScreen = document.querySelectorAll('[openFullScreen]')

let playBtn = ''



Promise.all([preloadImages(), preloadFonts('epq1pif', 'Montserrat')]).then(() => {
    // Remove loader (loading class)

    document.body.classList.remove('loading')

    gsap.from(['.mainTitle > h2', '.mainTitle > h1', '.mainTitle > button', '.mainTitle > span'  ], {
        y: 150,

        duration: 3.5,
        ease: "expo.inOut",
        stagger: .2
    })

    gsap.from('#headerCover', {
        scale: 1.4,
        duration: 4.7,
    })

    gsap.to('.curtain', {
        scaleY: 0,
        rotateX: "10deg",
        backgroundColor: '#FCD96D',

        duration: 3.2,
        ease: 'expo.inOut'
    })

})


// Smooth scroll
const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    // gestureDirection: 'both',

});



linkOpenFullScreen.forEach(v => {
    v.addEventListener('click', e => {


        g('.player').toggleAttribute('open');

        swiper.slideTo(e.currentTarget.dataset.link)
    })
})

g('.toggleGlobalPlayer').addEventListener('click', (e) => {
    g('.player').toggleAttribute('open');
    // window.location.hash == '#player' ? window.location.hash = '#' : window.location.hash = 'player'
})

// Tabs
tab.forEach(v => {
    if (v.dataset.tab == 'tab') {
        v.addEventListener('click', (e) => {
            tab.forEach(val => {
                if (val == e.currentTarget) {
                    console.log(document.querySelector(`${e.currentTarget.dataset.content}`));
                    val.setAttribute('active', 'true');
                    document.querySelector(`${e.currentTarget.dataset.content}`).style.display = 'block'
                } else {
                    document.querySelector(`${val.dataset.content}`).style.display = 'none'
                    val.removeAttribute('active');
                }
            })
        })
    }
})




// Slider
Swiper.use([Navigation, EffectCoverflow, HashNavigation]);
const swiper = new Swiper('.swiper-container', {
    effect: 'coverflow',
    grabCursor: true,
    loop: false,

    centeredSlides: true,
    slidesPerView: 3,
    spaceBetween: -10,
    coverflowEffect: {
        rotate: 15,
        stretch: 20,
        depth: 200,
        modifier: 1,
        slideShadows: false,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    pagination: {
        el: '.swiper-pagination',
    },
    breakpoints: {
        1280: {
            spaceBetween: -200,
            rotate: 50,
        }
    },
    on: {
        slideChange: function (e) {

            album = new Album(albums.albums[e.activeIndex], '#musicList')
            playBtn = g('.playBtn', true)


        },

    }
})




swiper.slideTo(3)
// Player
const plyr = new Plyr(document.querySelector('.globalPlayer'), {
    controls: ['play', 'progress', 'current-time', 'download']
})

let audio = g('audio')

audio.addEventListener('play', () => {
    g(`.playBtn[data-track="${parseInt(audio.dataset.track)}"]`).parentElement.setAttribute('playing', 'playing');
    g('.globalPlayerContainer').style.display = 'block';
})
audio.addEventListener('pause', () => {
    g(`.playBtn[data-track="${parseInt(audio.dataset.track)}"]`).parentElement.removeAttribute('playing')
})

audio.addEventListener('ended', () => {
    let e = g(`.playBtn[data-track="${parseInt(audio.dataset.track) + 1}"]`)
    console.log(e)
    e ? e.click() : e.parentElement.removeAttribute('playing')
})


// Message
console.log(
    '%c Desenvolvido por: https://lucascoutinho.com.br 🎨',
    'background: #4d4d4d; border: 1px solid #000; padding: 4px; padding-top: 10px; padding-bottom: 8px;'
);



g('#tocar').addEventListener('click', () => {

    g('.playBtn').click()
})