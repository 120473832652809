const albums = [

    {
        title: 'Pedaço de Giz',
        fichaTecnica: `           <ul class="list-group list-group-flush ">
        <li class="list-group-item"><strong>Todas as Letras: </strong>Roberto Coutinho</li>
        <li class="list-group-item"><strong>Todas as Músicas: </strong>André de Oliveira</li>
        <li class="list-group-item"><strong>Gravação, mixagem e masterização: </strong>Estúdio
            Miolo</li>
        <li class="list-group-item"><strong>Edição de Baterias e Percussões: </strong>Joãozinho
            Costa</li>
        <li class="list-group-item"><strong>Violões e Guitarras: </strong>Sammy Erick</li>
        <li class="list-group-item"><strong>Viola e Violão em "Roça": </strong>Joãozinho Costa
        </li>
        <li class="list-group-item"><strong>Teclados e Efeitos": </strong>Joãozinho Costa</li>


    </ul>`,
        spotifyURL: 'https://open.spotify.com/album/2Nbs4r5UjjxiI1jqHYtK9a?si=GBr9Yn7xSDmz7JVC0I3LsA',
        youtubeURL: 'https://www.youtube.com/watch?v=ShKh9vAB61E&list=OLAK5uy_l3lXVSZENL23q4Cd5yfKAYR2iWys2uuk8',
        downloadLink: 'audio/Pedaços de Giz - Canções de Um Sonho - André de Oliveira & Roberto Coutinho.zip',
        tracks: [
            {
                track: 1,
                title: "Março",
                singer: 'Caleb de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Março.mp3",
                lyric: `Ainda é verão!<br>
        breve outono, vocês verão!<br>
        recolhimento do coração<br>
        <br>
        Março, sinto falta do seu abraço,<br>
        que me aperta feito um laço<br>
        e acaba com o meu cansaço!<br>
        <br>
        Eu me perco no “teu” amasso<br>
        Coração antes duro feito aço,<br>
        hoje se faz alegre feito um palhaço<br>
        <br>
        Venham as águas de março<br>
        vou pra rua dançar, eu já ensaiei o passo!<br>
        eu e você no mesmo compasso!<br>
        <br>
        Feliz, você é o amor<br>
        que eu sempre quis.<br>
        quero me casar com você,<br>
        no alto da Torre Eiffel, em Paris!<br>
        <br>
        Vamos voar pra Marte, o planeta vermelho!<br>
        retoque sua maquiagem<br>
        no espelho do Sena!<br>
        amar você vale a pena!<br>
        <br>
        No espelho do Sena!<br>
        amar você vale a pena!<br>
        <br>
        Passem os meses, as estações!<br>
        nunca as emoções, as ilusões!<br>
        venham as chuvas de Março!<br>
        nutrem de sonhos nossos corações<br>
        <br>
        Passem os meses, as estações,<br>
        nunca as emoções, as ilusões!<br>
        venham as chuvas de Março!<br>
        nutrem de sonhos nossos corações...<br>
        Ainda é verão!<br>
        breve outono, vocês verão!<br>
        recolhimento do coração...<br>
        <br>
        Ainda é verão!<br>
        
        `
            },
            {
                track: 2,
                title: "Multidão",
                singer: 'André de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Multidão.mp3",
                lyric: `O palco está vazio<br>
        há um ar, um ar sombrio<br>
        o artista devia cantar pra multidão se alegrar!<br>
        <br>
        Quanta solidão na canção!<br>
        hoje eu sou a multidão!<br>
        meu coração se divide em milhão<br>
        e aplaude com emoção!<br>
        <br>
        O canto é alto e deve ir pro infinito<br>
        leva a alma do artista<br>
        voz, violão, paixão em vista!<br>
        A canção não pode esperar!<br>
        é o amor em palavras,<br>
        vou aplaudir o vazio do bar<br>
        quem sabe o amor cansou de amar<br>
        <br>
        cante, cante muito<br>
        cantar faz sonhar!<br>
        de repente chega alguém pra escutar!<br>
        dançar!<br>
        <br>
        Palmas pra quem tem alma pra cantar!<br>
        hoje eu sou a multidão!<br>
        <br>
        A canção não pode esperar!<br>
        É o amor em palavras<br>
        <br>
        Vou invadir o vazio do bar<br>
        quem sabe o amor cansou de amar<br>
        <br>
        Cante, cante muito!<br>
        Cantar faz sonhar...<br>
        de repente chega alguém pra escutar!<br>
        dançar!<br>
        <br>
        Palmas pra quem tem alma pra cantar!<br>
        Hoje eu sou a multidão!<br>
        `
            },
            {
                track: 3,
                title: "Laços",
                singer: 'Sérgio Gerê',
                audioSrc: "audio/pedaco-de-giz/Laços.mp3",
                lyric: `Saudade é quando você<br>
        não passa na rua...<br>
        tudo fica finito<br>
        pouco importa se a lua cheia<br>
        Flutua no infinito!<br>
        <br>
        Na calçada só o flash da luz<br>
        solitária no granito<br>
        Não tem o seu olhar de infinito<br>
        a iluminar meus olhos!<br>
        não tem a graça do bonito!<br>
        Apenas da paixão, um abafado grito!<br>
        <br>
        Venha no seu caminhar que faz sonhar!<br>
        pode ser na hora incerta<br>
        a porta estará sempre aberta<br>
        venha me aperta em seus braços<br>
        a saudade já atou os laços!<br>
        Hummmm! Laçooos!<br>
        `
            },
            {
                track: 4,
                title: "Eu Te Amo",
                singer: 'Caleb de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Eu Te Amo.mp3",
                lyric: `Alguém escreveu no muro<br>
        “eu te amo", não fui eu, juro!<br>
        Caminho no escuro, na brisa, inseguro!<br>
        Você é único sonho meu de futuro!<br>
        Eu tatuei seu nome no meu coração,<br>
        você é minha única oração.<br>
        <br>
        Escrevi seu nome em toda a Muralha da China,<br>
        gritei alto que "eu te amo" em toda esquina<br>
        o mundo agora sabe<br>
        que você é minha menina!<br>
        <br>
        Pensei alto e minha voz ecoou no asfalto<br>
         "eu te amo", a multidão, em sobressalto, bateu palmas!<br>
        As pessoas agitadas ficaram calmas,<br>
        quando no amor dois corações se encontram, revelam-se as almas! Ahhh!<br>
        <br>
        O dia amanhece, você não aparece<br>
        o escrito na muralha, o vento espalha<br>
        a tatuagem no coração corta feito navalha!<br>
        Fica só o escrito no muro<br>
        "eu te amo" e disso estou seguro!<br>
        Apenas preciso do seu amor e um pouco de ar puro!<br>
        Oh... Oh... Oh!<br>
        `
            },
            {
                track: 5,
                title: "Festa Sem Você",
                singer: 'Rosângela Tavares',
                audioSrc: "audio/pedaco-de-giz/Festa Sem Você.mp3",
                lyric: `Festa sem você não tem balão<br>
        não tem confete, nem serpentina<br>
        ninguém quer ficar e festejar<br>
        a chegada da estrela Matutina!<br>
        não tem tambor<br>
        não tem tamborim<br>
        eu até vou...<br>
        mas fico com dó de mim!<br>
        não tem tambor<br>
        não tem tamborim<br>
        eu até vou...<br>
        mas fico com dó de mim!<br>
        o surdo bate no peito<br>
        a cuíca  no coração<br>
        pandeiro e xique-xique tocam sem emoção!<br>
        o surdo bate no peito<br>
        a cuíca  no coração<br>
        pandeiro e xique-xique tocam sem emoção!<br>
        <br>
        Quando você chega linda assim<br>
        a batucada se transforma na Orquestra de Berlim<br>
        A estrela da manhã chega pra dançar<br>
        fica triste<br>
        é só com você que eu quero sambar!<br>
        A estrela da manhã chega pra dançar<br>
        fica triste...<br>
        é só com você que eu quero sambar!<br>
        <br>
        Festa sem você não tem balão<br>
        não tem confete, nem serpentina<br>
        ninguém quer ficar e festejar<br>
        a chegada da estrela Matutina!<br>
        não tem tambor<br>
        não tem tamborim<br>
        eu até vou...<br>
        mas fico com dó de mim!<br>
        o surdo bate no peito<br>
        a cuíca  no coração<br>
        pandeiro e xique-xique tocam sem emoção!<br>
        `
            },
            {
                track: 6,
                title: "Pedaço de Giz",
                singer: 'Renilda Tânia',
                audioSrc: "audio/pedaco-de-giz/Pedaço de Giz.mp3",
                lyric: `Pedaço de giz<br>
        um dia sonhei ter você ao meu lado<br>
        por mais que eu tenha disfarçado<br>
        muitas vezes sonhei acordado!<br>
        Sorriso largo apaixonado<br>
        alegria pulsante<br>
        um amor puro de um iniciante, porém desconfiado!<br>
        <br>
        Você a cada dia mais distante<br>
        às vezes como uma estrela fugitiva<br>
        Orbitava um mundo distante<br>
        e o meu amor seguia à deriva<br>
        Hoje não sei se você é feliz<br>
        hummm, parece que sim!<br>
        eu ainda sonho os mesmos sonhos<br>
        o que foi feito de mim?<br>
        Pedaço de giz na orelha do tempo,<br>
        doido para escrever a história do amor<br>
        do amor que eu nunca hei de ter...<br>
        Não sei se você é feliz<br>
        hummm! Parece que sim!<br>
        eu ainda sonho os mesmos sonhos<br>
        O que foi feito de mim?<br>
        Pedaço de giz na orelha do tempo,<br>
        doido para escrever a história do amor<br>
        do amor que eu nunca hei de ter...<br>
        `
            },
            {
                track: 7,
                title: "Roça",
                singer: 'André de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Roça.mp3",
                lyric: `Eu sou do mato<br>
aqui grudado feito carrapato<br>
apuro sete vidas de gato<br>
apronto e não pago o pato<br>
aí, aí!<br>
<br>
Dou um trago e jogo um pouco no mato<br>
essa é minha oração de fato<br>
se precisar, mato<br>
mas prefiro ignorar o ingrato!<br>
<br>
Pescador nato<br>
não é mentira, nem boato...<br>
aqui não tem ira<br>
linda flor roxa da Sucupira;<br>
na cidade a vida pira,<br>
prefiro viver no anonimato!<br>
<br>
Aprecio o rio, a Traíra<br>
a Mãe d'água, a Maíra,<br>
o canto da Saíra<br>
aqui a gente se vira aí, aí!<br>
Só não brinco com o amor,<br>
anzol traiçoeiro!<br>
a gente pode até se livrar do primeiro<br>
Outros vem e cravam por inteiro<br>
descanso na roça o meu cansaço<br>
        `
            },
            {
                track: 8,
                title: "Voar",
                singer: 'Caleb de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Voar.mp3",
                lyric: `Voar sobre as nuvens<br>
        pássaro metálico!<br>
        sonhar sobre as nuvens<br>
        coração solitário!<br>
        <br>
        Voar sobre as nuvens<br>
        pássaro metálico!<br>
        sonhar sobre as nuvens<br>
        coração solitário!<br>
        <br>
        Pra que horário<br>
        se a visão é infinita?<br>
        Você é tão bonita!<br>
        Faz meu coração voar!<br>
        <br>
        Flutuar no céu<br>
        nas nuvens esquiar!<br>
        nuvens escuras<br>
        soltas no ar!<br>
        <br>
        Como é frágil a vida!<br>
        Pássaro metálico nas nuvens!<br>
        Voo preciso, amor indeciso!<br>
        se preciso, é paraíso!<br>
        <br>
        Seus braços, asas<br>
        o infinito azul fica pequeno<br>
        Seu beijo, nuvem de emoção;<br>
        voa solitário coração!<br>
        <br>
        Seus braços, asas<br>
        o infinito azul fica pequeno<br>
        <br>
        Seu beijo, nuvem de emoção;<br>
        voa solitário coração!<br>
        <br>
        Para que horário<br>
        se a visão é infinita?<br>
        Você é tão bonita!<br>
        faz o meu coração voar!<br>
        `
            },
            {
                track: 9,
                title: "Ramos",
                singer: 'Joãozinho Costa & Giulia Costa',
                audioSrc: "audio/pedaco-de-giz/Ramos.mp3",
                lyric: `A chuva molhou as plantas<br>
        que estão fora das casas<br>
        deu às Aleluias asas<br>
        Voaram pra morte ou pra vida!<br>
        <br>
        O pássaro bateu asas, voou lindo!<br>
        causou inveja à NASA<br>
        A Paineira se floriu;<br>
        A Paineira faz da paina sua asa;<br>
        triste é o homem que não viu!<br>
        e não sorriu,<br>
        são lindos os dias de abril!<br>
        <br>
        O céu se coloriu<br>
        a Procissão de Ramos<br>
        faz refletir que nada somos...<br>
        Sem amor, ahhh! Pobres ramos!<br>
        <br>
        Com Jesus há vida!<br>
        há ressurreição!<br>
        encontro com os ausentes<br>
        somos árvores, frutos e sementes...<br>
        Sementes, sementes...<br>
        <br>
        A chuva molhou as plantas<br>
        que estão fora das casas<br>
        deu às aleluias asas<br>
        voaram pra morte ou pra vida!<br>
        `
            },
            {
                track: 10,
                title: "Inundar",
                singer: 'André de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Inundar.mp3",
                lyric: `Nuvens de amor<br>
        ora azuis, claras, escuras<br>
        pesadas, carregadas...<br>
        <br>
        Nuvens de saudade<br>
        tempestades de chuvas!<br>
        no amor há muitas curvas,<br>
        lembranças turvas!<br>
        <br>
        Nuvens de raios<br>
        Gritos de tristezas<br>
        Como é cruel do amor a incerteza!<br>
        <br>
        Nuvens de raios<br>
        Gritos de tristezas<br>
        Como é cruel do amor a incerteza!<br>
        <br>
        Nuvens de enxurrada<br>
        Meio-fio, barro, tudo veloz<br>
        O amor é algoz!<br>
        na mesa do bar, a sós!<br>
        <br>
        Nuvens de rio<br>
        sorrio, calafrio, vou pro mar...<br>
        o que era doce, é sal!<br>
        o amor faz viver<br>
        mas pode ser mortal!<br>
        `
            },
            {
                track: 11,
                title: "Outono",
                singer: 'Renilda Tânia',
                audioSrc: "audio/pedaco-de-giz/Outono.mp3",
                lyric: `Outono tempo de recolhimento<br>
        de reflexão, de transformação<br>
        as noites serão menores a cada dia<br>
        o amor dos homens menor a cada dia!<br>
        <br>
        O coração do homem não muda<br>
        é frio como as manhãs,<br>
        cada dia mais endurecido<br>
        mentira, injustiça, maldade<br>
        rotinas das cidades<br>
        e o ser humano<br>
        cada vez mais enfurecido!<br>
        <br>
        Deveria ser diferente<br>
        espalhar a alegria feito semente<br>
        gente abraçando gente<br>
        afinal todo o mundo é parente<br>
        à luz do sol poente!<br>
        <br>
        Outono!<br>
        Outono da natureza, de plena riqueza!<br>
        transforma o coração do homem<br>
        este lobisomem,<br>
        que troquem o ódio pelo amor<br>
        neste tempo transformador,<br>
        que o Outono traga bom sono<br>
        na bênção do Criador...<br>
        <br>
        O coração do homem não muda<br>
        É frio como as manhãs!<br>
        cada dia mais endurecido<br>
        mentiras, injustiças, maldades<br>
        rotinas das cidades<br>
        e o ser humano<br>
        cada vez mais enfurecido<br>
        <br>
        Deveria ser diferente<br>
        espalhar a alegria feito semente<br>
        gente abraçando gente<br>
        afinal todo o mundo é parente!<br>
        à luz do sol poente!<br>
        <br>
        Outono! <br>
        É tempo de recolhimento<br>
        de reflexão, de transformação<br>
        as noites serão maiores a cada dia<br>
        o amor dos homens menor a cada dia!<br>
        `
            },
            {
                track: 12,
                title: "Bolinhas de Sabão",
                singer: 'André de Oliveira',
                audioSrc: "audio/pedaco-de-giz/Bolinhas de Sabão.mp3",
                lyric: `O que canto<br>
        e faz você se alegrar...<br>
        é o mesmo que de saudade,<br>
        a sós, que me faz chorar...<br>
        <br>
        O que canto<br>
        e faz você se alegrar...<br>
        é o mesmo que de saudade,<br>
        a sós, que me faz chorar...<br>
        <br>
        Dissimular a dor<br>
        é só pra quem tem muito amor<br>
        Eu só fico feliz se você sorrir<br>
        Por isso canto e invento histórias<br>
        pra te divertir!<br>
        <br>
        Faço piruetas, caretas e pinto o sete<br>
        Faço bolas de chicletes!<br>
        aponto os lápis de cores<br>
        com meu mais novo canivete!<br>
        <br>
        Mas o que canto<br>
        e faz você se alegrar<br>
        é o mesmo que de saudade,<br>
        a sós, que me faz chorar!<br>
        <br>
        Vamos sim desenhar o sol<br>
        nos dias de chuva<br>
        barquinhos a navegar<br>
        bolinhas de sabão<br>
        colorindo a tarde<br>
        <br>
        Até que chegue a noite<br>
        com suas estrelas...<br>
        e você adormece...<br>
        no meu ombro amigo...<br>
        Sem vê-las!<br>
        <br>
        Mas o que canto<br>
        e faz você se alegrar...<br>
        é o mesmo que de saudade,<br>
        a sós, que me faz chorar...<br>
        <br>
        O que canto<br>
        e faz você se alegrar...<br>
        é o mesmo que de saudade,<br>
        a sós, que me faz chorar...<br>
        `
            }

        ]
    },
    {
        title: 'Uni...Verso',
        fichaTecnica: `
             <ul>
            <li><strong>Todas as Letras: </strong>Roberto Coutinho</li>
            <li><strong>Todas as Músicas: </strong>André de Oliveira</li>
            <li><strong>Teclados e Programação: </strong>Celson Ramos</li>
            <li><strong>Baixos: </strong>Celson Júnior</li>
            <li><strong>Percussão: </strong>Matheus R</li>
            <li><strong>Sopros: </strong>Marcelo Reis</li>
            <li><strong>Sanfona: </strong>Sérgio Saraiva</li>
            <li><strong>Guitarra e Violão: </strong>Samy Erick</li>
            <li><strong>Guitarra em Juízo: </strong>Arnon Júnior</li>
            <li><strong>Triângulo em "Xote da Alegria": </strong>Zé Cymar</li>
            <li><strong>Vocais: </strong>Gerê (Sérgio) e Emmy Matias</li>
            <li><strong>Arranjos: </strong>Celson Ramos e André de Oliveira</li>
            <li><strong>Estúdio: </strong>"Seu Som"</li>
            <li><strong>Música, Arranjo, Guitarra e Outros Instrumentos em "Universo de Bares": </strong>Saymon Maia
            </li>
            <li><strong>Arte Capa: </strong>Lucas Coutinho</li>
            </ul>
          `,
        spotifyURL: 'https://open.spotify.com/album/1HBGM4X1Q15pQd4hNdK0yk?si=iIKEzcKpSMOrMhccMJjHrg',
        youtubeURL: 'https://www.youtube.com/watch?v=Z_gX18ohQzk&list=OLAK5uy_kSGyLWjYDty8HxPzY6QYEkzTI7Zb7y99w',
        downloadLink: 'audio/Uni...Verso - André de Oliveira & Roberto Coutinho.zip',
        tracks: [
            {
                track: 1,
                title: "Amada",
                singer: 'André de Oliveira',
                audioSrc: "audio/uni-verso/Amada.mp3",
                lyric: `Tantas vezes disse a mesma coisa<br>
                Disse que não te amo<br>
                E olha eu de novo te amando...<br>
                Disse que iria embora<br>
                Olha eu voltando<br>
                Que ficaria calado<br>
                Olha eu falando<br> 
                Que não traria rosas<br>
                Olha as rosas que peguei<br>
                Que nunca mais iria te ver<br>
                Não tiro os meus olhos de teus olhos<br>
                E sei bem porque<br>
                Outra coisa não sei fazer<br>
                Agora não digo mais nada<br>
                Só que tu és minha amada<br>
                Ah! O que eu digo é sem razão<br>
                Escute só o que diz<br>
                O meu coração...<br>
                Eu disse que não traria rosas<br>
                Olha as rosas que peguei<br>
                Que nunca mais iria te ver<br>
                Mais não tiro os meus olhos de teus olhos<br>
                E sei bem<br>
                Sei bem porque<br>
                Ah! Que não traria rosas<br>
                Olha as rosas que peguei<br>
                Que nunca mais iria te ver<br>
                Não tiro os meus olhos dos teus olhos<br>
                E sei bem porque<br>
                Éh tantas vezes eu disse a mesma coisa<br>
                Disse que não te amo!<br>
                E olha eu de novo te amando...<br>
                `
            },
            {
                track: 2,
                title: "Borboleta Amarela",
                singer: 'Emmy Matias',
                audioSrc: "audio/uni-verso/Borboleta Amarela.mp3",
                lyric: `Abra a janela veja a flor rosa<br>
                A borboleta amarela<br>
                A flor amarela<br>
                A borboleta rosa<br>
                Asas abertas feito janela!<br>
                Pétalas voando nas asas da borboleta<br>
                A borboleta vivendo na flor,<br>
                Pétalas voando nas asas da borboleta<br>
                Abra a janela, meu amor!<br>
                Pinte na mente esta aquarela<br>
                A vida triste fica bela!<br>
                No encontro da borboleta rosa<br>
                Com a rosa-flor amarela!<br>
                Agora são milhares de borboletas<br>
                Milhares de rosas nos jardins<br>
                Nos campos, no céu...<br>
                Pegue o lápis<br>
                Passe para o papel pra mim<br>
                A magia da janela aberta<br>
                A janela do coração...<br>
                Vá correndo chame o vizinho<br>
                Não é bom olhar sozinho...<br>
                O mundo de magia e de cor<br>
                Você acaba de descobrir o amor!<br>
                Só falta beijar a rosa-flor<br>
                E voar com a borboleta<br>
                Pra onde ela for...<br>
                `
            },
            {
                track: 3,
                title: "Templário",
                singer: 'Gerê (Sérgio)',
                audioSrc: "audio/uni-verso/Templário.mp3",
                lyric: `Queria estar no seu diário<br>
                Estar nos seus braços<br>
                Você é meu mundo,<br>
                Meu amor!<br>
                Nos portas-retratos<br>
                Sobre os armários<br>
                Diga-me o que eu faço<br>
                Pra onde vou<br>
                Para ter teus beijos<br>
                Que tanto almejo<br>
                Amolecer esse coração de aço<br>
                Você é meu mundo imaginário!<br>
                Amor extraordinário<br>
                O que eu faço<br>
                O que eu faço<br>
                Queria estar nos seus sonhos<br>
                Sonhos diários<br>
                Preso nos seus braços, meu amor<br>
                Cavalgue coração<br>
                Coração templário<br>
                Mude o itinerário<br>
                Contigo estou para ter os teus beijos<br>
                Que tanto almejo<br>
                Amolecer esse coração de aço<br>
                Você é meu mundo imaginário<br>
                Fé e relicário<br>
                Queria estar no seu diário<br>
                Estar nos teus braços<br>
                Você é meu mundo, meu amor!<br>
                Nos portas-retratos sobre os armários<br>
                Diga-me o que eu faço<br>
                Para onde vou<br>
                Pra ter os teus beijos que tanto almejo<br>
                Amolecer esse coração de aço<br>
                Você é meu mundo imaginário!<br>
                Amor extraordinário<br>
                O que eu faço<br>
                Me diz o que eu faço<br>
                `
            },
            {
                track: 4,
                title: "Orbitar",
                singer: 'Andréia',
                audioSrc: "audio/uni-verso/Orbitar.mp3",
                lyric: `Uma estrela se partiu!<br>
                Milhares de fragmentos!<br>
                Fez lindos desenhos no céu<br>
                Pena que eram poucos os olhares!<br>
                Deixou um vazio imenso<br>
                Nos meus pensamentos<br>
                Era de uma estrela solitária!<br>
                Solidão às vezes nos joga pelos ares!<br>
                Vazios de mesas de bares...<br>
                Tentei desenhar!<br>
                A estrela que me fez sonhar!<br>
                Pena que não teve o seu olhar!<br>
                Viajou anos-luz no tempo!<br>
                Nunca mais essa estrela vai voltar...<br>
                O que importa se você fechou<br>
                Janelas e portas!<br>
                Os fragmentos estão no fundo do mar...<br>
                Meu amor está no fundo do mar<br>
                O amor que eu tenho por você está no fundo<br>
                Do meu coração!<br>
                Doido pra no seu céu<br>
                Orbitar!<br>
                Tentei desenhar!<br>
                A estrela que me fez sonhar!<br>
                Pena que não teve o seu olhar!<br>
                Viajou anos-luz no tempo!<br>
                Nunca mais essa estrela vai voltar...<br>
                Vai voltar<br>
                Nunca mais vai voltar<br>
                Vai voltar<br>
                `
            },
            {
                track: 5,
                title: "Cavalgar",
                singer: 'Eduardo Nunes e Isabella Nunes',
                audioSrc: "audio/uni-verso/Cavalgar.mp3",
                lyric: `Meu cavalo imaginário<br>
                Cavalga na solidão do pensamento<br>
                Sem horário, viaja pelo tempo<br>
                Devagar vai trotando o momento!<br>
                Relincha de saudade!<br>
                O amor é um pasto seco<br>
                Que a paixão teima em molhar<br>
                Arame farpado<br>
                Mata-burro, buraco<br>
                Estrada de barro!<br>
                Cavalgar!<br>
                Cavalgue "puro sangue" de amor!<br>
                Ah se ela voltar!..<br>
                Jogo fora esporas!<br>
                Solto o freio e arreio!<br>
                E deixo de cavalgar<br>
                Tomara que chegue essa hora!<br>
                Guardo a sela<br>
                Acendo a vela<br>
                Vou só nos beijos dela!<br>
                Cavalgar<br>
                Hum, hum, hum, hum<br>
                `
            },
            {
                track: 6,
                title: "Rio Afogado",
                singer: 'Ben-Hur e Tim',
                audioSrc: "audio/uni-verso/Rio Afogado.mp3",
                lyric: `Todos os dias misturo as palavras<br>
                Versos que depois vou lapidar<br>
                Alguns tem sentido, mas não rima<br>
                Outros rimam sem sentido<br>
                Alguns animam e outros te desanima<br>
                Na contradição das coisas, na contramão das ruas<br>
                É nos desencontros que muito se pode fazer<br>
                A sorte pode estar no azar<br>
                E a dor pode estar no prazer...<br>
                O rio se banha no mar<br>
                Nas quedas, cachoeira fica mais bonito<br>
                No seu leito não incomoda, dorme<br>
                Fora, tira o sono, mata a fome!<br>
                Torna-se infinito<br>
                Queria o seu amor, até pediria por favor<br>
                Lapidaria as palavras<br>
                Procuraria nas lavras<br>
                As pedras mais raras<br>
                Sim as pedras mais raras<br>
                Era feliz o tempo que você me amava...<br>
                E acalmava o meu rio de amor<br>
                Agora inundou-se de dor<br>
                E um rio afogado eis o que sou...<br>
                Na contradição das coisas, na contramão das ruas<br>
                É nos desencontros que muito se pode fazer<br>
                A sorte pode estar no azar<br>
                E a dor pode estar no prazer...<br>
                `
            },
            {
                track: 7,
                title: "Poesia",
                singer: 'Ninho Mathias',
                audioSrc: "audio/uni-verso/Poesia.mp3",
                lyric: `Hoje é seu dia!<br>
                Não te detenhas,minha poesia<br>
                Tomar os meus sentimentos<br>
                Momentos!<br>
                De outra forma eu não diria<br>
                O que digo é em versos<br>
                Quando tu me iludes<br>
                É um deus nos acuda!<br>
                Os dias são rudes<br>
                Se não fossem teus versos<br>
                Eu nada seria, poesia!<br>
                Se você não houvesse<br>
                Céu estrelado não haveria<br>
                De tristeza a gente iria morrer<br>
                E a luz do sol desapareceria<br>
                Sem sonhos, sem amores<br>
                Ah! Quando tu me iludes<br>
                É um deus nos acuda!<br>
                Os dias são rudes<br>
                Se não fossem teus versos<br>
                Eu nada seria, poesia<br>
                Hoje é seu dia!<br>
                Não te detenhas!<br>
                Venha poesia<br>
                Tomar os meus sentimentos<br>
                Momentos<br>
                De outra forma eu não diria<br>
                Tudo que eu digo em versos<br>
                Minha poesia<br>
                De outra forma eu não diria<br>
                O que eu digo em versos<br>
                Minha poesia<br>
                `
            },
            {
                track: 8,
                title: "Uni...Verso",
                singer: 'Caleb de Oliveira',
                audioSrc: "audio/uni-verso/Uni...Verso.mp3",
                lyric: `Só mais um verso<br>
                Um simples verso!<br>
                O uni...verso!<br>
                Verso, finito, infinito<br>
                Perdido na imensidão do espaço<br>
                Na emoção de um abraço...<br>
                Tudo é poesia no universo<br>
                Estrela solitária, só mais um verso<br>
                Na medida do coração, da solidão<br>
                Só mais um verso<br>
                Um simples verso, meu amor<br>
                O uni...verso!<br>
                Estrela, buraco negro<br>
                Amor, paixão, saudade!<br>
                Real, virtual, verso, reverso!<br>
                Amar nunca fez mal!<br>
                Vida plana, tridimensional<br>
                Amar é plano...<br>
                Além do intencional!<br>
                Do incondicional...<br>
                Mais é só mais um verso<br>
                Um simples verso, meu amor<br>
                O uni...verso!<br>
                Vértices de desenganos<br>
                Adeus sonhos, planos<br>
                Quero uni...verso no meu verso<br>
                E meu coração a girar, girar<br>
                Girar no uni...verso!<br>
                Ôh, ôh, ôh, ôh.....<br>
                Só mais um verso<br>
                Um simples verso!<br>
                O uni...verso!<br>
                Verso, infinito, finito<br>
                Perdido na imensidão do espaço<br>
                Na emoção de um abraço...<br>
                Ôh, ôh, ôh, ôh.....<br>
                `
            },
            {
                track: 9,
                title: "Desigual",
                singer: 'Chico da Viola',
                audioSrc: "audio/uni-verso/Desigual.mp3",
                lyric: `Lê, lerê, lê, lerê,<br>
                Queria ver o amanhecer,<br>
                Pássaros às centenas!<br>
                Cantando nas árvores e nas antenas<br>
                As horas seriam mais amenas<br>
                O “vai-e-vém” das morenas<br>
                Ter a real dimensão que só o amor é grande<br>
                Pena que o homem se apega às coisas pequenas<br>
                Depois chora suas penas nas novenas...<br>
                Em nome do progresso,<br>
                A natureza envenena<br>
                Das aves não sobram nem as penas<br>
                No rio fica o lixo, a podridão<br>
                Mata o pescador de solidão...<br>
                À tarde, "ave maria"<br>
                Vida vazia, canta o sabiá<br>
                O homem reza pra deus o abençoar<br>
                E amanhã bem cedo vai de novo matar...<br>
                Triste se recolhe o pardal<br>
                Um dia desses sabe que a natureza<br>
                Será apenas um registro<br>
                Nos "achados & perdidos" do jornal<br>
                A luta é cruel, desigual<br>
                Mas que dure<br>
                Ah! Que dure até o último pardal...<br>
                Eh, lê, lê, lê, lera<br>
                A luta é cruel, desigual<br>
                Mas que dure até o último pardal...<br>
                Eh, lê, lê, lê, lera<br>
                Lê, lê, lê, lê, lê, lê,lera<br>
                Até o último pardal...<br>
                `
            },
            {
                track: 10,
                title: "Repica Tamborim",
                singer: 'Renilda Tânia',
                audioSrc: "audio/uni-verso/Repica Tamborim.mp3",
                lyric: `Você sem fantasia<br>
                Era tudo que eu queria<br>
                Nesta quarta-feira de cinzas<br>
                Acabar com as horas tristes,<br>
                Horas tristes ranzinzas!<br>
                Sambe só pra mim!<br>
                Sinta a batida do tamborim<br>
                Só você samba assim!<br>
                No seu sambar<br>
                A alegria não tem fim!<br>
                No meu tamborim<br>
                Vou fazer chorar<br>
                As horas que eu fiquei<br>
                Sem te amar!<br>    
                As horas que você sambou<br>
                Éh! Sambou pra multidão sonhar...<br>
                Seu beijo é o meu desejo<br>
                Foi de quem veio olhar!<br>
                Sem fantasias<br>
                Vou com você sambar<br>
                Só eu sei<br>
                Só eu sei te amar!<br>
                Até o amor em apoteose<br>
                Vai aplaudir!<br>
                O que me fez triste<br>
                Hoje me fez sorrir!<br>
                Repica tamborim<br>
                Essa noite, essa noite<br>
                Não terá fim!<br>
                Ai,ai<br>
                `
            },
            {
                track: 11,
                title: "Xote da Alegria",
                singer: 'Zé Cymar',
                audioSrc: "audio/uni-verso/Xote da Alegria.mp3",
                lyric: `Com um pouco se faz alegria<br>
                Um sorriso<br>
                Um bom dia<br>
                Um abraço<br>
                Muito mais quem abraçou<br>
                Tão feliz certamente ficaria<br>
                Outro dia<br>
                Ganhei um sorriso<br>
                Um abraço e um bom dia<br>
                Foi como ganhar na loteria<br>
                Ai foi como ganhar na loteria<br>
                Depositei no cofre do coração<br>
                Rendeu sonhos<br>
                E emoção<br>
                O dia ficou mais lindo<br>
                Que a mais linda canção<br>
                Com um pouco se faz alegria<br>
                Experimente ao nascer do dia<br>
                Saia pela rua sorrindo<br>
                Abraçando<br>
                E dizendo bom dia<br>
                `
            },
            {
                track: 12,
                title: "Luar",
                singer: 'Emmy Matias',
                audioSrc: "audio/uni-verso/Luar.mp3",
                lyric: `Queria sonhar, amar você<br>
                Ter o seu beijo só pra mim<br>
                Queria sonhar, amar você<br>
                Um sonho sem fim<br>
                Na força do pensamento<br>
                Ver você chegar<br>
                Linda feito o céu estrelado<br>
                Sem luar<br>
                Queria sonhar, amar você<br>
                Ter o seu beijo só pra mim<br>
                Você é a mais linda estrela<br>
                A iluminar minha solidão<br>
                Ah! Como almejo vê-la<br>
                És minha maior paixão!<br>
                Eu queria sonhar, amar você<br>
                Ter o seu beijo só pra mim<br>
                Ah, ah, ah<br>
                Hum, hum, hum<br>
                Vamos dançar nessa escuridão<br>
                Rosto colado, o vento assobia<br>
                O vento sabe o que eu já sonhei<br>
                Sonhei por esse dia<br>
                Eu queria sonhar e amar você<br>
                Ter o seu beijo só pra mim<br>
                O tempo é ligeiro<br>
                O amor pode ser passageiro<br>
                Quero ser o dono dos seus beijos<br>
                Não tive os primeiros<br>
                O que importa só os derradeiros...<br>
                Queria sonhar, amar você<br>
                Sonho real<br>
                Ter o seu beijo só pra mim<br>
                Chegue antes do final<br>
                Amor assim não há igual<br>
                Chegue amor<br>
                Venha por favor<br>
                Na rua agora, só eu<br>
                O sonho e lua...<br>
                Meu amor<br>
                Mas eu queria sonhar<br>
                Amar você<br>
                Ter o seu beijo só pra mim<br>
                Queria sonhar<br>
                Amar você<br>
                Um sonho sem fim<br>
                `
            },
            {
                track: 13,
                title: "Amor",
                singer: 'Nelma Gonçalves (Mel)',
                audioSrc: "audio/uni-verso/Amor.mp3",
                lyric: `É preciso cantar a dor<br>
                Talvez a dor um dia<br>
                Quem sabe vire alegria...<br>
                A tristeza se faça bonita<br>
                Colorida!<br>
                E o que é o fim<br>
                Vire o início da vida!<br>
                Vamos lá vida<br>
                Chega de ferida   <br>
                Vamos brindar a vinda<br>
                Antes da partida!<br>
                É preciso cantar a dor<br>
                Talvez a dor um dia<br>
                Quem sabe vire alegria...<br>
                A tristeza se faça bonita<br>
                Colorida!<br>
                E o que é o fim<br>
                Vire o início da vida!<br>
                Vamos lá vida<br>
                Chega de ferida       <br>
                Vamos brindar a vinda<br>
                Antes da partida!<br>
                Já chorei muito<br>
                Hoje quero alegria<br>
                Mesmo que nascida da dor...<br>
                Hoje finalmente<br>
                Descobri o que é o amor!<br>
                Hum, hum, hum, hum...<br>
                `
            },
            {
                track: 14,
                title: "Abril",
                singer: 'Gerê (Sérgio)',
                audioSrc: "audio/uni-verso/Abril.mp3",
                lyric: `O sol se abriu no horizonte<br>
                Abriu a caixinha de sonhos<br>
                A flor se abriu                 <br>
                Abre o seu coração, o sorriso!<br>
                E vive este paraíso!<br>
                Abriu a vida...<br>
                Abriu, abriu, abril!<br>
                Pena que só a tristeza sorriu...<br>
                É mentira que não "te amo"!<br>
                É verdade que "te quero"<br>
                Falo mentira, mas sou sincero!<br>
                Todas as estações por você espero...<br>
                Cantou o bem-te-vi<br>
                "ti vi"! Ti vi"! Ti vi"! Bem...    <br>
                Bem, meu bem, então feliz vivi!<br>
                Fechou, abriu, outono recolhimento!<br>
                E você não sai do meu pensamento<br>
                Vamos felizes, meu amor, viver este raro momento!<br>
                Novos planos, adeus desenganos<br>
                Este será o melhor abril de todos os anos!<br>
                Novos sonhos, mais de mil...<br>
                Éh! Desabrochou abril!<br>
                Abriu a vida...<br>
                Abriu, abriu, abril!<br>
                Pena que só a tristeza sorriu...<br>
                É mentira que não "te amo"!<br>
                É verdade que "te quero"<br>
                Falo mentira, mas sou sincero!<br>
                Todas as estações por você espero...<br>
                Cantou o bem-te-vi<br>
                "ti vi"! Ti vi"! Ti vi"! Bem...    <br>
                Bem, meu bem, então feliz vivi!<br>
                Cantou o bem-te-vi<br>
                "ti vi"! Ti vi"! Ti vi"! Bem...    <br>
                Bem, meu bem, então feliz vivi!<br>
                `
            },
            {
                track: 15,
                title: "Amanhecer",
                singer: 'Renilda Tânia',
                audioSrc: "audio/uni-verso/Amanhecer.mp3",
                lyric: `Olá estrela solitária<br>
                Seu brilho é esperança...<br>
                Na escuridão<br>
                Queria tocar-lhe com a mão<br>
                Na verdade queria compartilhar<br>
                Minha solidão...<br>
                Se amei, não foi em vão<br>
                Não me faça sonhar<br>
                Hoje cheguei ao fundo do universo...<br>
                Só tenho você, estrela solitária<br>
                E este verso...<br>
                Antes do amanhecer...<br>
                `
            },
            {
                track: 16,
                title: "Aldeia",
                singer: 'Chico da Viola',
                audioSrc: "audio/uni-verso/Aldeia.mp3",
                lyric: `Velho pescador,<br>
                Faça-me um favor<br>
                Conte-me o que você sabe<br>
                Do amor e do mar...<br>
                Ah! Se o amor fosse um peixe!...<br>
                Não iria mais pescar...<br>
                Pois não queria o amor fisgado<br>
                Apenas mergulhar lado a lado<br>
                Na imensidão do mar!<br>
                Fazer piruetas, voar<br>
                Abraçar a estrela-do-mar, sonhar!<br>
                O mar seria o meu coração<br>
                Os rios, as veias...<br>
                Ah! Se o amor fosse um peixe!...<br>
                Em noite de lua cheia<br>
                Dançaria com a sereia...<br>
                Não poria o pé na areia!<br>
                Seria o mais feliz peixe de minha aldeia...<br>
                `
            },
            {
                track: 17,
                title: "Colando",
                singer: 'Zé Cymar',
                audioSrc: "audio/uni-verso/Colando.mp3",
                lyric: `Quebra coco<br>
                Quebra mar<br>
                Quebra onda<br>
                Só não quebra a paixão!<br>
                Quebra barco<br>
                Quebra anzol<br>
                Quebra o sol<br>
                Só não quebra a saudade<br>
                Do meu coração!<br>
                Quebra o amor<br>
                Quebra rochedo, pedregulho, paredão<br>
                Quebra concha<br>
                Só não quebra a solidão!<br>
                Quebra a vida<br>
                Eu queria quebrar toda ilusão<br>
                Quebra tudo<br>
                E deixe o sonho<br>
                Não quebra não!<br>
                Ver você vir linda na minha direção...<br>
                Onda de amor<br>
                Quebrando tudo<br>
                No vão<br>
                E o povo assustado<br>
                Colando o meu coração...<br>
                `
            },
            {
                track: 18,
                title: "Acenar",
                singer: 'André de Oliveira',
                audioSrc: "audio/uni-verso/Acenar.mp3",
                lyric: `Não quero ver conchas na areia<br>
        Quero ver conchas no mar!<br>
        Não quero o pescador na solidão da praia<br>
        Quero o pescador na solidão do mar<br>
        Não quero barco ancorado<br>
        Rede embolada, anzol quebrado<br>
        Quero o mar previsível!<br>
        A linha do horizonte visível<br>
        Quero a emoção das ondas<br>
        O voo em pirueta da gaivota<br>
        A incerteza da volta<br>
        O peixe que do anzol se solta<br>
        Quero a vida como sempre foi...<br>
        Mar traiçoeiro, vento forte,de proa<br>
        Mar que zoa, caçoa<br>
        Que magoa...<br>
        Não!<br>
        Não quero lagoa!<br>
        Não quero mar feito o amor<br>
        Pescador sabe navegar<br>
        Mas não sabe amar...<br>
        Amor paixão se faz da solidão<br>
        Não!<br>
        Eu não quero me afogar<br>
        Quero a doçura da solidão do mar<br>
        Deixe o amor da praia me acenar...<br>
        `
            },
            {
                track: 19,
                title: "Primavera",
                singer: 'Emmy Matias',
                audioSrc: "audio/uni-verso/Primavera.mp3",
                lyric: `É insistente essa tal primavera<br>
                É a estação das flores<br>
                Porque não a estação dos amores?<br>
                Dos sonhos<br>
                A estação dos sonhadores<br>
                E também dos namoradores?<br>
                Colocar fim às dores da saudade<br>
                Há tantas cores novas na cidade<br>
                Me diz por que não, novos amores?<br>
                Equinócio perfeito do dia e noite<br>
                Da paixão, do amor!<br>
                Pontual! Chegou num discreto sinal<br>
                Não há amor igual ao que brota<br>
                Na primavera<br>
                É assim desde outras eras<br>
                Ah quem me dera<br>
                Você nos meus braços<br>
                Nesta primavera<br>
                Eu sei é sonho, é quimera<br>
                Olhe! Meu amor a vida não espera...<br>
                Vou colher pra você<br>
                Uma flor do cerrado<br>
                Se você se fizer<br>
                Meu amado...<br>
                `
            },
            {
                track: 20,
                title: "Estrela-guia",
                singer: 'Caleb de Oliveira',
                audioSrc: "audio/uni-verso/Estrela Guia.mp3",
                lyric: `Seu brilho vai sumindo<br>
                Na luz do dia...<br>
                Sei que você está no meu dia<br>
                Às vezes sinto a sua companhia<br>
                Não consigo vê-la,<br>
                Oh minha estrela-guia<br>
                Olhar para o céu<br>
                Sentir o seu pulsar<br>
                É o mesmo que sinto<br>
                Daquele amor           <br>
                Que tanto me faz sonhar<br>
                Tanto me faz sonhar<br>
                Ela é estrela da minha vida<br>
                Quando aparece<br>
                Não adianta prece<br>
                Desaparece...<br>
                Fica seu perfume pelo dia<br>
                O sonhar me alumia<br>
                Ao amanhecer<br>
                Só tenho sua companhia...<br>
                Bom dia estrela-guia<br>
                `
            },
            {
                track: 21,
                title: "Juízo",
                singer: 'Tatiana Paula e Arnon Júnior',
                audioSrc: "audio/uni-verso/Juízo.mp3",
                lyric: `Quem bom você apareceu<br>
                Nos meus dias<br>
                Descobri que isso aumentou<br>
                Minha alegria...<br>
                Um novo sentido despontou<br>
                Feito um sol<br>
                Hoje estou me refazendo pra você<br>
                Quem sabe um dia desses<br>
                Por fim<br>
                Você vai perceber<br>
                E quando, quando, quando isso acontecer<br>
                De tão feliz acho que nem vou perceber...<br>
                E de amor vou morrer nos seus braços<br>
                Mas depois vou renascer<br>
                Nos seus beijos terei paraíso<br>
                Nada mais do que você preciso<br>
                Apenas hoje quero manter esse sonho<br>
                Enquanto tudo está indeciso<br>
                Você nos meus dias<br>
                Como um guizo...<br>
                Por favor não demore, não demore<br>
                Pois posso perder o juízo<br>
                Posso perder o juízo<br>
                Heié, heié<br>
                Perder o juízo<br>
                O juízo<br>
                `
            },
            {
                track: 22,
                title: "Universo de Bares",
                singer: 'Marlon Cardoso e Saymon Maia',
                audioSrc: "audio/uni-verso/Universo de Bares.mp3",
                lyric: `Queria morar na lua<br>
                Sem casa e sem rua<br>
                Sem roupas<br>
                Vida nua!<br>
                Sonhar só com você<br>
                Amar nas alturas!<br>
                Amor que flutua!<br>
                Você a minha única flor<br>
                Desse espaço sideral<br>
                Raios de sol, de lua<br>
                Vou girar no seu abraço<br>
                Como um satélite natural<br>
                Viver o que não era normal<br>
                Amor novo<br>
                A lua, um grande quintal<br>
                Um beijo sem outro igual<br>
                Tudo diferente<br>
                De longe vou dar adeus<br>
                A esse mar de gente!<br>
                Viver todas as fases da lua<br>
                Pelos bares do universo<br>
                Você a única razão<br>
                Do meu verso<br>
                Universo<br>
                Universo de bares<br>
                Que giram como satélites<br>
                Pelos ares...<br>
                Ou, ou, ou pelos ares<br>
                Queria morar na lua...<br>                
                `
            }

        ]
    },
    {
        title: 'Entardecer',
        fichaTecnica: `
             <ul>
            <li><strong>Todas as Letras: </strong>Roberto Coutinho</li>
            <li><strong>Todas as Músicas: </strong>André de Oliveira</li>
            <li><strong>Teclados e Programação: </strong>Kal Robson</li>
            <li><strong>Baixo: </strong>Kal Robson</li>
            <li><strong>Gaita: </strong>Marcelo</li>
            <li><strong>Violão Base: </strong>André de Oliveira</li>
            <li><strong>Guitarra, Violão 12 Cordas, Viola, Ukulelê, Violão de Aço: </strong>Kal Robson</li>
            <li><strong>Arranjos: </strong>Kal Robson e André de Oliveira</li>
            <li><strong>Arranjo e Execução em "Magia": </strong>Banda Joker High</li>
            <li><strong>Arte Capa: </strong>Lucas Coutinho</li>
            <li><strong>Estúdio: </strong>Gerência</li>
            <hr style="opacity:.5;">
            <li><strong>Banda Joker High: </strong></li>
            <li><strong>Vocal: </strong>Leo Ramos</li>
            <li><strong>Guitarra: </strong>Diego Cod</li>
            <li><strong>Teclado e Backing Vocal: </strong>Benny Texeira</li>
            <li><strong>Baixo: </strong>Felipe Tavares</li>
            <li><strong>Bateria: </strong>Rafa Leitão</li>
            <li><strong>Violão: </strong>Márcio Jr</li>

            
            </ul>
          `,
        spotifyURL: 'https://open.spotify.com/album/528AxqtTPR0ecexnTlX592?si=CpYQePMfQNG3zycRVpjd-w',
        youtubeURL: 'https://www.youtube.com/watch?v=C_T6ggQIKao&list=OLAK5uy_m5sSRSDyiT8vnXZquNZ85Lq3EAe5VZykc',
        downloadLink: 'audio/Entardecer - André de Oliveira & Roberto Coutinho.zip',
        tracks: [
            {
                track: 1,
                title: "Cantar",
                singer: "Júlia Ribas",
                audioSrc: "audio/entardecer/cd1/1 - Cantar.mp3",
                lyric:
                    `
Eu canto<br>
mas não sei cantar<br>
o que importa<br>
é se seu o seu coração<br>
quer me escutar!<br>
Meu canto fala de amor<br>
Amor escondido<br>
de saudade de ver você<br>
naquele lindo vestido!<br>
Até perco o sentido<br>
Abro os braços<br>
aperto o meu violão<br>
e canto a mais linda canção!<br>
mas não sei cantar<br>
só sei te amar<br>
eu não sei cantar<br>
só sei te amar<br>
eu não sei cantar<br>
Escute só a letra<br>
sinta a melodia<br>
é uma poesia<br>
que fiz outro dia<br>
pra você voltar...<br>
Canto feito passarinho<br>
de manhã bem cedinho<br>
ao entardecer<br>
só pra ter o seu carinho!<br>

`},
            {
                track: 2,
                title: "Liz",
                singer: "Daniel Veiga",
                audioSrc: "audio/entardecer/cd1/2 - Liz.mp3",
                lyric:
                    `
A saudade é extrema<br>
A ausência queima<br>
Nos teus braços sou feliz!<br>
Prefiro Extrema do que Paris!<br>
Minha linda Liz!<br>
Minha linda Liz!<br>
Minha linda Liz!<br>
Do teu lado sou aprendiz!<br>
Sou criança!<br>
Só escuto o que você me diz<br>
Minha linda Liz!<br>
Vamos sonhar juntos<br>
teus sonhos serão os meus!<br>
Escreva a palavra papai, minha filha!<br>
Veja como a vida brilha!<br>
Viajarei um milhão de milhas<br>
Velocidade extrema<br>
Vontade extrema<br>
Amor puro da gema!<br>
Minha linda Liz!<br>
Como fica linda Belo Horizonte<br>
Quando você  defronte,<br>
    sorrir pra mim!<br>
Seu olhar tudo me diz<br>
Do teu lado Liz,<br>
    aprendi a ser feliz!<br>
Minha linda Liz!<br>
Minha linda Liz!<br>
Minha filha<br>

`
            },
            {
                track: 3,
                title: "Outra Rua",
                singer: "Emmy Matias",
                audioSrc: "audio/entardecer/cd1/3 - Outra Rua.mp3",
                lyric:
                    `
Hoje mudei de flores<br>
caminhei por outra rua...<br>
Lindas flores<br>
nunca tinha visto “igual”!<br>
    Janelas diferentes<br>
grades e portões<br>
Alegrias diferentes<br>
que coisa comovente!<br>
A vida toda sem passar<br>
na outra rua...<br>
O que perdi ?<br>
    talvez um olhar que nunca vi!<br>
uma flor nova na janela<br>
de repente!<br>
    - Meu Deus é ela!<br>
Ainda bem<br>
que cantou o Bem - te - vi<br>
que canta aqui e ali...<br>
Canta o amor que ainda não<br>
vivi...<br>
`

            },
            {
                track: 4,
                title: "Mudança",
                singer: "Cláudia Rocha",
                audioSrc: "audio/entardecer/cd1/4 - Mudança.mp3",
                lyric:
                    `
As coisas mudam sem a gente<br>
perceber...<br>
algumas você nunca<br>
saberá dizer<br>
se mudou<br>
por que mudou?...<br>
Estranho! Por que mudou ?<br>
    muda a árvore<br>
o Céu<br>
muda o tempo<br>
a tarde<br>
as estações!<br>
A foto registra um instante<br>
não registra a emoção de quem fotografou...<br>
Você parece mais nova<br>
Quanto tempo!<br>
o que há de novo ?<br>
    Há tanta coisa que não vi direito<br>
e agora mudou...<br>
o meu silêncio é triste...<br>
queria ter visto<br>
cada detalhe da mudança...<br>
ontem você era criança...<br>
e a tarde era tarde...<br>

`

            },
            {
                track: 5,
                title: "Cavalo Marinho",
                singer: "Zé Cymar",
                audioSrc: "audio/entardecer/cd1/5 - Cavalo Marinho.mp3",
                lyric:
                    `
Quero os meus versos soltos<br>
Cavalo selvagem a galopar...<br>
Nas campinas dos sonhos<br>
Dar coice no ar de alegria<br>
Relinchar de amor<br>
Pular a tristeza, rosetar!<br>
Buscar a felicidade!<br>
Monte poesia, venha “maestrar”<br>
Dome esse cavalo do meu peito<br>
Vá puro sangue, que se dane a saudade<br>
Hoje é o dia!<br>
Que esse amor não vai mais voltar!<br>
Vá feito mula sem cabeça<br>
Desse amor não esqueça<br>
Vá cavalo selvagem, pule, sacode e morde<br>
Depois pode voar<br>
Jogue esse amor no chão<br>
Pise, não avise, poeira no ar<br>
Galope! Adeus amor!<br>
Ensope de suor e dor!<br>
Mergulhe no rio, nade até o mar<br>
Não há mais carinho...<br>
Em Cavalo Marinho,<br>
    Vamos - nos transformar!<br>
E viver na imensidão do mar...<br>

`
            },
            {
                track: 6,
                title: "Insistência",
                singer: "Miriam Zanutti",
                audioSrc: "audio/entardecer/cd1/6 - Insistência.mp3",
                lyric:
                    `
Enquanto houver uma flor<br>
no pé de Ipê...<br>
anunciando sei, sei lá o quê<br>
talvez saudade de você ?<br>
    vou pensar no buquê<br>
que faria só pra você!<br>
flores sob a luz do luar<br>
ficavam lindas<br>
a visão infinita assim<br>
como o amor<br>
que quem ama sente<br>
espera que alguém<br>
também sinta sob a luz do luar<br>
seja onde for...<br>
Queria te dar pelo menos<br>
uma flor só<br>
a última, solitária<br>
bastante pra quem está só<br>
Ah pena de mim<br>
você não tem dó!<br>
pena de mim<br>
você não tem dó!<br>
Por isso o ipê floriu<br>
uma vez só<br>
o amor não pode não pode<br>
Ser uma vez só<br>
depois simplesmente virar pó<br>
Flores secas<br>
O Ipê insistente<br>
não flori<br>
uma vez só...<br>

`
            },
            {
                track: 7,
                title: "Tarde azul",
                singer: "Nelma Gonçalves(Mel)",
                audioSrc: "audio/entardecer/cd1/7%20-%20Tarde%20azul.mp3",
                lyric:
                    `
Céu azul, nuvens esparsas<br>
Eu sozinho sem graça...<br>
Voam  no meu pensamento<br>
garças<br>
garças da paixão!<br>
Voam e levam o meu coração<br>
Parece ir na sua direção<br>
Tudo é ilusão<br>
Quem  sabe<br>
seu coração também<br>
procura por mim!<br>
nesta imensidão...<br>
Este encontro em pleno ar<br>
Vou sonhar<br>
Preciso te encontrar<br>
neste azul sem fim de tarde...<br>
Voem garças sem parar<br>
voem ligeiro<br>
quero chegar...<br>
bem antes do sol<br>
no horizonte se quebrar...<br>
`

            },
            {
                track: 8,
                title: "Seu nome",
                singer: "Eduardo Nunes",
                audioSrc: "audio/entardecer/cd1/8 - Seu nome.mp3",
                lyric:
                    `
Escrevi seu nome<br>
nas águas cristalinas do rio<br>
a correnteza não levou o meu escrito<br>
a flutuar no infinito do rio ficou!<br>
Flores aquáticas nasceram<br>
um lindo bordado<br>
a refletir na luz da Lua!<br>
o amor verdadeiro nada vai apagar<br>
a Natureza quer mais é compartilhar...<br>
Você distante não, não pode olhar!<br>
acredite pescador sabe sonhar<br>
o sonho vira realidade na hora de contar...<br>
As flores agora devagar descem o rio<br>
fugaz  feito uma estrela cadente<br>
o pulo do Dourado no “Sol Poente”!<br>
    Agora um vazio<br>
o seu nome ainda está<br>
nas águas do rio...<br>
no fundo
profundo, meu amor...<br>
`

            },

            {
                track: 9,
                title: "Junto de mim",
                singer: "Andréia Bastos",
                audioSrc: "audio/entardecer/cd1/9 - Junto de Mim.mp3",
                lyric:
                    `
Já vi você mais feliz<br>
no tempo que seu prazer<br>
parecia ser me fazer infeliz!<br>
Hoje nem escuto<br>
o que o povo me diz<br>
Você arrependido<br>
agora quer me fazer feliz!<br>
Vou tatuar nas cicatrizes<br>
palavras que do amor<br>
quem tem paixão diz<br>
Quem sabe a gente se casa<br>
em Paris!<br>
Diga - me num longo beijo<br>
se posso acreditar<br>
que dessa vez<br>
você veio pra ficar<br>
e comigo vai sonhar<br>
Se não for assim<br>
Não deixe que essa noite<br>
tenha fim!<br>
Seja feliz hoje junto de mim!<br>

`
            },
            {
                track: 10,
                title: "Preço",
                singer: "Emmy Matias",
                audioSrc: "audio/entardecer/cd1/10 - Preço.mp3",
                lyric:
                    `
De repente o vazio das coisas!<br>
A vida vista no mosaico da tela...<br>
O infinito é a sala, é o quarto!<br>
O infinito é a sala, é o quarto!<br>
O infinito é...<br>
Não dá para escapar!<br>
Não se escapa da saudade<br>
do amor<br>
da solidão...<br>
Viver às vezes é se encontrar<br>
com o nada...<br>
O nada do recomeço...<br>
O recomeço do nada...<br>
Ah, ah, ah...<br>
Vou virar o meu bolso ao avesso!<br>
Feliz viver<br>
penso que mereço<br>
mas não sei o preço...<br>
`
            },
            {
                track: 11,
                title: "Carrapichos",
                singer: "Yule Rossi",
                audioSrc: "audio/entardecer/cd1/11 - Carrapichos.mp3",
                lyric:

                    `
Quero seu sorriso de criança<br>
Festejar as coisas maravilhosas<br>
Fazer palhaçadas<br>
Gargalhadas estrondosas<br>
Andar descalço<br>
Ruas de terra, chuva de barro<br>
E tirar da tristeza um sarro!<br>
Não quero colher a flor<br>
Quero quebrar o jarro...<br>
Espalhar sementes de amor...<br>
Vamos pedras, cipós<br>
poeira, barrancos e trancos<br>
solavancos, tocos, pregos<br>
arame farpado<br>
aí vou e arranco<br>
Quero abrir as porteiras dos sonhos<br>
Você com seus caprichos<br>
Mesmo que seu amor<br>
cole no meu corpo feito carrapichos!<br>
`
            },
            {
                track: 12,
                title: "Big Bang",
                singer: "Cláudia Rocha",
                audioSrc: "audio/entardecer/cd1/12 - Big Bang.mp3",
                lyric:
                    `
Foi um grande amor<br>
Inexplicavelmente infinito<br>
Grandes amores também terminam inexplicavelmente...<br>
Ontem nave espacial a girar na imensidão<br>
Hoje sem órbita, sem carinho, sem chave da porta<br>
Como se os bares fossem Antares<br>
Só restou o fundo dos mares...<br>
Seus beijos eram mais quentes que o sol!<br>
Girava a minha vida em órbitas felizes<br>
Acabou...inexplicavelmente acabou!<br>
Seu abraço era a única órbita...<br>
Nada restou!<br>
Isso é a prova maior que o infinito tem fim...<br>
Buraco Negro, solidão...<br>
Ou quem sabe!<br>
Big Bang de uma nova paixão<br>
Recomeço do infinito do fim...<br>
Não sei o que será de você e nem de mim...<br>

`
            },
            {
                track: 13,
                title: "Magia",
                singer: "Joker high",
                audioSrc: "audio/entardecer/cd1/13 - Magia.mp3",
                lyric:
                    `
Eu aluguei um megafone<br>
e em seguida gritei seu nome<br>
um drone jogou pétalas de flores<br>
e a tarde de repente se fez em cores!<br>
grandes amores<br>
invasores dos sonhos<br>
revelam - se na loucura<br>
da solidão...<br>
Isolaram a rua com cones<br>
aplaudiu a multidão<br>
nasceram asas<br>
o povo voou!..<br>
meu coração agora é um drone<br>
a girar de amor<br>
meu coração agora é um drone<br>
a girar de amor<br>
ATV talvez mostre<br>
no jornal que o amor<br>
trouxe tecnologia<br>
mas não sei se vão falar<br>
que não perdeu a magia!<br>
grandes amores invasores dos sonhos<br>
revelam - se na loucura  da solidão...<br>
Isolaram a rua com cones<br>
aplaudiu a multidão<br>
nasceram asas<br>
o povo voou!<br>
meu coração agora é um drone<br>
a girar de amor<br>
`
            },
            {
                track: 14,
                title: "Pensamento veloz",
                singer: "André de Oliveira",
                audioSrc: "audio/entardecer/cd1/14 - Pensamento Veloz.mp3",
                lyric:
                    `
Pensamento veloz<br>
vento no rosto<br>
coração acelerado<br>
montanhas do lado<br>
o amor é algo inesperado...<br>
miragem turva<br>
de saudade deslizo nas curvas<br>
meus olhos se enchem de chuvas<br>
estradas infinitas levam o meu coração<br>
o vento assobia uma canção<br>
partir, chegar é só emoção!<br>
O silêncio faz  eco nas montanhas<br>
canta sobre as duas rodas<br>
amigos se encontram!<br>
a  vida é uma estrada a rodar<br>
o amor é o que se quer encontrar!<br>
muitas cilindradas<br>
vamos juntos meus camaradas<br>
no próximo posto<br>
a gente dá uma parada<br>
olhe só o sol<br>
apontando na estrada<br>
ainda pouco brilhava<br>
a estrela da madrugada!<br>
acelera!<br>
a tristeza já era...<br>
acelera!<br>
a tristeza já era...<br>
acelera!<br>
a tristeza já era...<br>
capotou na estrada!<br>
acelera!<br>
a tristeza já era...<br>
capotou na estrada!<br>

`

            },
            {
                track: 15,
                title: "Sonhando",
                singer: "Inês Sadala",
                audioSrc: "audio/entardecer/cd1/15 - Sonhando.mp3",
                lyric:
                    `
Eu só queria que você voltasse<br>
não mude de caminho<br>
a sua ausência é espinho!<br>
Tudo bem que você nem me olha!<br>
é tão triste esperar sozinho<br>
nessa mesa de bar...<br>
Seu perfume fica na tarde<br>
Ah meu Deus como arde!<br>
o amor que não é seu...<br>
Eu só queria que você voltasse<br>
não mude de caminho<br>
a sua ausência é espinho!<br>
Lá vem ela linda, divina<br>
dá contornos à esquina!<br>
seu amor vai desafiando a paixão<br>
e zomba da solidão...<br>
O que ela está pensando ?<br>
    vai passar e me deixar sonhando<br>
essa forma de amar, mata!<br>
faz viver!<br>
a esperança desempata!<br>
Olha, no céu despontou a lua nova<br>
de prata...<br>
`

            },
            {
                track: 16,
                title: "Porta",
                singer: "Daniel Veiga",
                audioSrc: "audio/entardecer/cd1/16 - Porta.mp3",
                lyric:
                    `
Se o ouro é preto<br>
branco, amarelo<br>
não importa<br>
é ouro!<br>
Se homem é preto<br>
branco, amarelo<br>
não importa!<br>
é homem!<br>
O que importa é que viemos<br>
da mesma porta...<br>
e voltaremos pela mesma porta!<br>
As luzes das velas são<br>
brancas, amarelas<br>
pretas!<br>
Dia<br>
Tarde, noite<br>
Clarão, escuridão...<br>
e não importa as cores<br>
“Disco de Newton” a girar!<br>
Deus vai abrir a porta...<br>
`



            },
            {
                track: 17,
                title: "Voo cego",
                singer: "Tatiana Paula",
                audioSrc: "audio/entardecer/cd2/1 - Vôo cego.mp3",
                lyric:
                    `
O voo não é novo<br>
não tem nada a ver com o que já voei...<br>
o plano de voo é diferente<br>
Joguei fora as minhas penas<br>
estou mais leve, feito filhote...<br>
e carrego só, só coisas amenas...<br>
Entreguei - me à própria sorte<br>
sonhos de Ícaro, quero ir mais alto<br>
olhar de cima, flutuar na imensidão...<br>
Ver a silhueta das montanhas<br>
espreguiçar, fazer manhas<br>
novas façanhas!<br>
As nascentes, o caminho tortuoso do rio<br>
dos iniciantes<br>
sentir de novo o frio<br>
rodopiar no ar, parafusos, piruetas!<br>
Amores antigos hoje nego...<br>
a nada mais me apego!<br>
aterrizar nos braços<br>
de um novo amor!<br>
o voo pode ser cego...<br>
`

            },
            {
                track: 18,
                title: "Acontecer",
                singer: "Andréia Bastos",
                audioSrc: "audio/entardecer/cd2/2 - Acontecer.mp3",
                lyric:
                    `
Venha ver a chuva<br>
chegou de mansinho<br>
feito o seu carinho<br>
Eu sozinho a sonhar!<br>
Não quero o Sol<br>
quero a chuva<br>
Tarde turva<br>
O amor se curva no seu olhar!<br>
Quando você me abraça<br>
Sei lá o que acontece<br>
neste universo parece uma prece!<br>
O Sol aparece no meio da chuva<br>
Arco - íris no céu!<br>
Meu coração feito avião de papel<br>
gira no seu céu...<br>
Chuva, Sol, você<br>
A vida é assim<br>
isso me faz viver!<br>
Deixe esse amor acontecer...<br>
Olhe a nuvem<br>
tá querendo chover...<br>
e o Sol deste amor aparecer!<br>
`
            },
            {

                track: 19,
                title: "Vou voltar",
                singer: "Zé Cymar",
                audioSrc: "audio/entardecer/cd2/3 - Vou voltar.mp3",
                lyric:
                    `
Ainda vou voltar<br>
tomar uma cerveja<br>
com meus amigos no bar<br>
Lá a gente sonhava<br>
pouco importa se o sonho<br>
vai vingar...<br>
Lá a Lua aparecia nua<br>
quebrando o silêncio da rua!<br>
Lá o coração batia forte<br>
quando a morena olhava<br>
pra todo mundo<br>
só não olhava pra mim!<br>
sabia que era amor<br>
isso poderia ser o meu fim!<br>
Ainda vou voltar<br>
a mesa vazia está no mesmo lugar<br>
o copo cheio de sonhos<br>
espumando ao luar...<br>
Ainda vou voltar...<br>
mesmo que você só com o coração<br>
fique a me olhar<br>
ainda vou voltar...<br>
lá o coração batia forte<br>
quando a morena olhava<br>
pra todo mundo<br>
só não olhava pra mim!<br>
sabia que era amor<br>
e isso poderia ser o meu fim...<br>
`

            },
            {
                track: 20,
                title: "Chegar",
                singer: "Caleb de Oliveira",
                audioSrc: "audio/entardecer/cd2/4 - Chegar.mp3",
                lyric:
                    `
Queria ver você chegar<br>
linda de fazer sonhar<br>
mais ainda quem te ama<br>
sem você notar!<br>
Já guardei as palavras<br>
pro dia em que você voltar<br>
mas eu sei que na hora<br>
vou me enrolar<br>
Quem sabe de início<br>
você ache engraçado<br>
mas fique ao meu lado!<br>
olhe como o céu se fez estrelado!<br>
em pleno dia ensolarado!<br>
te contar sobre esse amor<br>
é tudo que tenho pensado e sonhado!<br>
nada mais é mais lindo<br>
do que ver você chegar...<br>
vou pensar<br>
vou sonhar...<br>
Hah...Hah...Hah<br>

`

            },
            {
                track: 21,
                title: "Condor",
                singer: "Renilda Tânia",
                audioSrc: "audio/entardecer/cd2/5 - Condor.mp3",
                lyric:
                    `
Quando não sonho nada<br>
os versos ficam tristonhos<br>
e não criam raízes<br>
e se perdem como perdizes...<br>
Quando sonho pouco<br>
os versos ficam tristonhos<br>
são pássaros da madrugada<br>
à procura da amada<br>
Curiangos<br>
Candangos da vida...<br>
Quando sonho muito<br>
os versos ficam cheios de amor<br>
criam asas<br>
e voam como o Condor...<br>
como o Condor...<br>
`
            },
            {
                track: 22,
                title: "Oásis",
                singer: "Kal Robson",
                audioSrc: "audio/entardecer/cd2/6 - Oásis.mp3",
                lyric:
                    `
Você atravessa a rua<br>
o meu coração de amor flutua<br>
mais linda que a estrela da manhã<br>
rubra como a flor da romã!<br>
Você atravessa rapidamente<br>
que pena o sinal está verde novamente!<br>
seus olhos tem mais luz que o farol<br>
na minha vida você poderia ser o sol!<br>
Você não olha e nem pisca pra mim<br>
parece mais distante que a estrela  Aldebarã!<br>
de novo estarei sozinho aqui amanhã<br>
sonhando com o doce da romã!<br>
O que me resta é traduzir<br>
o momento incerto do sonho<br>
num simples verso!<br>
também você não lerá por certo!...<br>
Amanhã num piscar do farol<br>
você certamente estará por perto...<br>
assim como um oásis<br>
para um viajante<br>
no deserto!...<br>

`

            },
            {
                track: 23,
                title: "Amigo",
                singer: "Sérgio(Gerê)",
                audioSrc: "audio/entardecer/cd2/7 - Amigo.mp3",
                lyric:
                    `
Não sou melhor nem pior<br>
sou um montão de coisas certas<br>
misturadas ao montão de coisas erradas!<br>
que às vezes dá certo, às vezes dá errado...<br>
que às vezes dá certo, às vezes dá errado...<br>
Sempre estarei ao teu lado<br>
no certo ou no errado<br>
mesmo que não combinado<br>
mesmo que haja mau olhado<br>
feitiço dos bravos, dobrado<br>
ao teu lado estou confirmado!<br>
ao teu lado estou confirmado!<br>
ao teu lado...<br>
A vida bate que nem a água no barranco<br>
vamos lá, braços dados nos solavancos<br>
não caio, nem manco<br>
pode vir a tristeza, que eu seguro o tranco<br>
e a alegria ? Deixa que eu banco!...<br>
Fico feliz quando estou ao teu lado<br>
amigo é sorte ter te encontrado<br>
a vida sem amigo, não tem significado<br>
hoje sou forte, tenho sorte<br>
zombo da vida e da morte<br>
decido se vou para o sul ou para  norte!<br>
Amanhã se o vento mudar<br>
precisarei de uma mão mais forte<br>
afinal não é pra sempre<br>
que dura a sorte...<br>
Éh<br>
`
            },
            {
                track: 24,
                title: "Batalha Final",
                singer: "André de Oliveira",
                audioSrc: "audio/entardecer/cd2/8 - Batalha final.mp3",
                lyric:
                    `
Já vi no cinema duelo<br>
sensacional!<br>
o mocinho escapa no final!<br>
briga de malandro<br>
com navalha!<br>
era uma boa batalha!<br>
tudo era honesto<br>
e a vida voltava depois ao normal!<br>
Já vi no futebol chutes e caneladas<br>
já vi briga desigual nas calçadas<br>
malandro x trabalhador<br>
político x eleitor!<br>
nessa briga, o povo é sempre, sempre perdedor!<br>
Agora arrumaram pra mim<br>
um combate mortal<br>
eu só queria a vida normal!...<br>
nunca fui à China<br>
aliás pouco passava da  esquina<br>
agora viver preso em casa é minha rotina!<br>
esse vírus é fatal!<br>
vai te matar diz o jornal!<br>
a TV mostra  como vai ser o final...<br>
a voz do Galvão Bueno:<br>
- Vai que a virose é sua<br>
se sair na rua!<br>
Mas eu não vou feito bobo iniciar essa briga!<br>
se eu morrer!<br>
quem vai com meus amigos<br>
ao bar beber ?<br>
    vou ficar quietinho<br>
se acontecer a batalha final...<br>
dou um bicudo nesse vírus chinês<br>
eu mato e espinico de vez!<br>
ah...<br>
depois melhor explico<br>
só não quero que algum interessado,<br>
    talvez  político,<br>
        me coloque no xadrez!<br>
por acabar com aquilo<br>
que o faz rico<br>

`
            },
            {
                track: 25,
                title: "Sentença",
                singer: "Lorena Mourão e Kal Robson",
                audioSrc: "audio/entardecer/cd2/9 - Sentença.mp3",
                lyric:
                    `
Você me ama ?<br>
    tem que manter isso, viu ? !<br>
        não há o que temer...<br>
O amor é uma forma de corrupção<br>
em troca da felicidade<br>
se entrega a propina e o coração<br>
Você me ama ?<br>
    tem que manter isso, viu ? !<br>
        não há o que temer...<br>
O amor é uma forma de corrupção<br>
em troca da felicidade<br>
se entrega a propina e o coração<br>
A paixão faz declarações  premiadas<br>
inventa fatos<br>
falsas gravações<br>
e vai aumentando os boatos<br>
disseminando contradições<br>
A saudade jura mentiras puras<br>
inventa qualquer agrura<br>
quem do beijo provou<br>
quase tudo a gente atura<br>
se por ventura houver amor<br>
Olha! tem que manter isso, viu ? !<br>
    o amor quer mais amor<br>
a paixão exige mais paixão<br>
a saudade a presença<br>
tudo conspira pra  prisão<br>
tem que manter isso, viu ? !<br>
    não importa qual será a sentença!...<br>
faz parte da delação premiada<br>
o subornado não pensa<br>
`
            },
            {
                track: 26,
                title: "Volver",
                singer: "Caleb de Oliveira",
                audioSrc: "audio/entardecer/cd2/10 - Volver.mp3",
                lyric:
                    `
Esquerda, Direita, volver!<br>
o Brasil precisa sobreviver...<br>
Esquerda, Direita, volver!<br>
o Brasil precisa desenvolver...<br>
Há famintos e desempregados<br>
e muitos com essa conversa<br>
sem significado<br>
e o povo morrendo à prestação<br>
morrendo à prestação<br>
fiado!<br>
Vamos para rua alma nua<br>
cantar o hino do Brasil<br>
essa pátria é minha<br>
é sua<br>
então, venha e construa!<br>
Esquerda, Direita, volver!<br>
o Brasil precisa sobreviver...<br>
para que tanto vai ou não vai<br>
Esquerda, Direita, o povo precisa<br>
precisa de rumo<br>
o povo precisa de prumo!<br>
Larga dessa loucura<br>
Direita pra frente!<br>
Esquerda pra frente!<br>
É urgente...<br>
há muita gente nesse esforço<br>
precisando urgente!<br>
Ideologia precisamos viver sim<br>
precisamos vencer<br>
Esquerda, Direita, volver!<br>
o Brasil precisa sobreviver...<br>
Esquerda, Direita, volver!<br>
o Brasil precisa sobreviver...<br>
`
            },
            {
                track: 27,
                title: "Vestido bordado",
                singer: "Renilda Tânia",
                audioSrc: "audio/entardecer/cd2/11 - Vestido Bordado.mp3",
                lyric:
                    `
Na rua não saio<br>
ainda posso ver<br>
o primeiro "Por do Sol" de Maio<br>
nos seus braços de amor<br>
quase desmaio!<br>
Não sei o que é mais bonito<br>
Você sorrindo...<br>
lá no infinito<br>
o Sol se despedindo!<br>
No céu bordado nas nuvens<br>
colorido pelo raios<br>
Você está tão distante<br>
mas é o meu sol<br>
a todo instante!<br>
A Lua e as estrelas completam o cenário<br>
Amanhã neste mesmo horário<br>
Vou esperar você<br>
com seu vestido bordado!<br>
aquele mesmo<br>
que faz o sol<br>
faz o sol ficar enciumado...<br>
quando você está ao meu lado!<br>

`
            },
            {
                track: 28,
                title: "Palavras",
                singer: "Sérgio(Gerê) e Andréia Bastos",
                audioSrc: "audio/entardecer/cd2/12 - Palavras.mp3",
                lyric:
                    `
Ontem sonhei com você<br>
hoje sonho mais ainda<br>
sim!<br>
sonhei com você<br>
e você está cada vez mais linda!<br>
Amanhã nem vou dormir<br>
vou sonhar acordado<br>
vou sonhar com<br>
você do meu lado<br>
Seu sorriso me deixa sem palavras<br>
sinfonias do coração<br>
lavras, cavas, oitavas<br>
milhares de travas!<br>
no vão...<br>
Cada dia me coloco numa cava!<br>
meu amor sei que isso é paixão<br>
"te amo"! é a única palavra<br>
desta lavra de ilusão<br>


`},
            {
                track: 29,
                title: "Chama",
                singer: "Lorena Mourão",
                audioSrc: "audio/entardecer/cd2/13 - Chama.mp3",
                lyric:
                    `
Eu espero você mesmo que você<br>
não olhe só para mim!<br>
Amar pode ser também assim<br>
deixa chover<br>
deixa tudo acontecer<br>
quem sabe eu e você!...<br>
vai chover!<br>
vai acontecer!<br>
Uma chuva de estrelas<br>
em pleno entardecer<br>
ninguém vai ver e nem entender<br>
só eu e você!<br>
Vejo estrelas no olhar!<br>
como é lindo seu olhar<br>
refletido no anoitecer!<br>
Seu olhar me vê por dentro<br>
vamos resolver<br>
diga que me ama<br>
deixe de drama, minha dama!<br>
Meu coração sua ausência reclama!<br>
parece que você me chama<br>
no meu coração há uma chama!<br>
o seu lugar nessa noite<br>
é a minha cama!<br>
diga que me ama!<br>
vamos resolver logo<br>
esse drama!<br>
Como você se chama ?<br>
`
            },
            {
                track: 30,
                title: "Entardecer",
                singer: "Inês Sadala",
                audioSrc: "audio/entardecer/cd2/14 - Entardecer.mp3",
                lyric:
                    `
Não sei pra que tanta tarde<br>
azul...<br>
queria só a tarde de seu olhar<br>
e dançar um blues!<br>
Pra que tanto céu ?<br>
    se no seu olhar vou viajar...<br>
uma estrela solitária<br>
acaba de chegar<br>
vi no reflexo de seu olhar...<br>
Infinitas cores<br>
um quadro magistral<br>
só nós dois nesta paisagem<br>
uma viagem sideral<br>
Envio mensagens<br>
seu sorriso é uma imagem<br>
que vem na lua nova<br>
no por do Sol<br>
e no horizonte<br>
faz uma aterrissagem...<br>
Faço  versos<br>
uma pequena trova<br>
só quem de seu beijo prova<br>
conta os segundos<br>
pra você aparecer<br>
antes do anoitecer!<br>
Eu queria te mostrar<br>
este entardecer...<br>
`
            },

            {
                track: 31,
                title: "Nada Importa",
                singer: "Roninho Silva",
                audioSrc: "audio/entardecer/cd2/15 - Nada Importa.mp3",
                lyric:
                    `
Meus olhos se fantasiaram de alegria<br>
meu coração de ilusões<br>
no bloco solitário do amor...<br>
vou sambar a minha dor...<br>
Ladeiras a baixo<br>
becos contramão!<br>
amar é sem direção!<br>
vou pra onde apontar a paixão...<br>
Pra onde apontar o nariz<br>
hoje quero mais é ser feliz!<br>
becos e becos sem saídas<br>
meu amor, amar é vida!<br>
Viver tem idas e vindas<br>
você será sempre bem - vinda!<br>
venha antes que a folia finda!<br>
há tempo ainda...<br>
Quarta - feira de cinzas<br>
não sei que fantasia vamos vestir<br>
não importa!<br>
o que quero mesmo é você<br>
sem fantasias<br>
sambando na minha porta!<br>
`
            },

            {
                track: 32,
                title: "Sementes",
                singer: "Márcio Antônio e Yuska",
                audioSrc: "audio/entardecer/cd2/16 - Sementes.mp3",
                lyric:
                    `
Vamos semear<br>
Vamos semear<br>
Vamos semear pra colher<br>
A cada segundo nasce uma flor<br>
no sertão, um fruto<br>
nova vida acontece!<br>
É também uma forma de oração!<br>
A esperança toda florida<br>
a festejar a vida!<br>
Alegria  transforma os homens<br>
em crianças!<br>
Deus se faz presente!<br>
Vivo na renovação da fé!<br>
Vivo no coração da gente!<br>
Lançamento de sementes do amor!<br>
“Deus - Vivo” nos sonhos, no abraço,<br>
    no olhar, no semear das palavras!<br>
em tudo que faço<br>
Vamos semear<br>
Na força da natureza do sertão<br>
No trabalho, na luta de seus filhos<br>
O amor renasce como semente!<br>
e a palavra aquece a mente!<br>
É Deus vivo para sempre!<br>
no árido do coração do homem!<br>
que feliz agora se sente!<br>
com Deus presente!<br>
pelo caminho a palavra a semear...<br>

`
            }



        ]
    }
]





export default { albums }