import { g } from './utils';
export default class Album {
    constructor(obj, HTMLtarget) {
        this.title = obj.title
        this.fichaTecnica = obj.fichaTecnica
        this.spotifyURL = obj.spotifyURL
        this.youtubeURL = obj.youtubeURL
        this.downloadLink = obj.downloadLink
        this.tracks = obj.tracks
        this.HTMLtarget = HTMLtarget
        return this.renderAll()
    }
    renderAlbumInfo() {

        
        g('[data-album-title]').innerText = this.title
        g('[data-album-youtubeLink]').href = this.youtubeURL
        g('[data-album-spotifyLink]').href = this.spotifyURL
        g('[data-album-downloadLink]').href = this.downloadLink
        g('[data-album-info]').innerHTML = this.fichaTecnica

        // let el =
        //     `
        //     <div class="albumInfo">
        //       <h2>${this.title}<small> 2019</small> </h2>
        //       <h3>André de Oliveira & Roberto Coutinho</h3>
        //       <ul class="inlineList">
        //       <li><a id="youtubeAlbum" href="${this.youtubeURL}"><img width="80" src="images/youtube.svg" alt=""> </a></li>
        //       <li><a id="spotifyAlbum" href="${this.spotifyURL}"><img width="80" src="images/spotify.svg" alt=""> </a></li>
        //       </ul>
        //     </div>
        //     <div class="controls">
        //       <button id="tocar"class="btn-primary"> <i class="uil uil-play"></i> Tocar</button>
        //       <a href="${this.downloadLink}" download><button class="btn-secondary"> <i class="uil uil-download-alt"></i> Download</button></a>
        //   </div>
        // `
        // document.querySelector(this.HTMLtarget).insertAdjacentHTML('afterbegin', el)
        // document.querySelector('#tabContent2').innerHTML = this.fichaTecnica
    }
    renderMusicas() {
        this.tracks.forEach(element => {
            let el =
                `
   
      <div class="musicCard" id="${element.track}">
      <button class="btn-player playBtn" data-music="${element.audioSrc}" data-track="${element.track}" >
      <i class="uil uil-play-circle"></i>
      </button>
      <div class="musicInfo" data-music="${element.audioSrc}">
      <h4>${element.title}</h4>
      <h5>${element.singer}</h5>
      </div>
      <button data-lcstoggle="#lyric${element.track}" class="btn-player lyricBtn">
       Letra <i class="uil uil-angle-down"></i>
      </button>
      <!--  
      <button  class="btn-player">
      <i class="uil uil-download-alt"></i>
      </button>
      -->
      <div class="lyric" id="lyric${element.track}" >${element.lyric}</div>
      </div>
 
      `
            document.querySelector(this.HTMLtarget).insertAdjacentHTML('beforeend', el)
        });
    }
    setListner() {
        // let audio = g('audio');
        let playBtn = g('.playBtn', true)
        let toggleLyric = g('[data-lcstoggle]', true)
        let musicInfo = g('.musicInfo', true)

        function playPause(elemento) {
            let e = elemento.currentTarget
            let card = e.parentElement
            playBtn.forEach(el => (el.parentElement.hasAttribute('playing') && el.parentElement != card) ? el.parentElement.removeAttribute('playing') : null)
            card.toggleAttribute('playing')
            if (card.hasAttribute('playing')) {
                g('audio').dataset.track = e.dataset.track
                g('audio').src = e.dataset.music
                g('audio').play()
            } else {
                g('audio').pause()
            }
        }
        function exandPanel(elemento) {
            let e = elemento.currentTarget
            let track = e.dataset.lcstoggle
            e.toggleAttribute('open')
            if (e.hasAttribute('open')) {
                g(track).style.display = 'block'
            } else {
                g(track).style.display = 'none'
            }
        }
        playBtn.forEach(el => {
            el.setAttribute('eventOn', 'eventOn')
            if (el.hasAttribute('eventOn')) {
                el.addEventListener('click', playPause)
            }
        })
        musicInfo.forEach(el => {
            el.setAttribute('eventOn', 'eventOn')
            if (el.hasAttribute('eventOn')) {
                el.addEventListener('click', () => {
                    // console.log(`${el.dataset.music}`)
                    g(`.playBtn[data-music="${el.dataset.music}"]`).click()
                })
            }
        })
        toggleLyric.forEach(el => {
            el.toggleAttribute('openLyric')
            if (el.hasAttribute('openLyric')) {
                el.addEventListener('click', exandPanel)
            }
        })


   
    }


    renderAll() {
        // document.querySelector(this.HTMLtarget).innerHTML = ''
        document.querySelector(this.HTMLtarget).innerHTML = ''
        this.renderAlbumInfo()
        this.renderMusicas()
        this.setListner()
    }
}